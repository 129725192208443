<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <br>

          <!-- Potencia -->
          <validation-provider
            v-if="origen === 25"
            #default="validationContext"
            name="Potencia"
            rules="required"
          >
            <b-form-group
              label="Potencia"
              label-for="potencia"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="full-name"
                v-model.number="itemData.valor"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Potencia en Números"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              :label="origen !== 25 ? 'Nombre' : 'Descripción'"
              label-for="nombre"
            >
              <b-form-input
                id="full-name"
                v-model="itemData.nombre"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nombre"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Trabajos Correctivos -->
          <div v-if="origen === 33">
            <!-- Código -->
            <validation-provider
              #default="validationContext"
              name="Código"
              rules="required"
            >
              <b-form-group
                label="Código"
                label-for="codigo"
              >
                <b-form-input
                  id="full-name"
                  v-model="itemData.codigo"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Código del Trabajo"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Código Nocturno-->
            <validation-provider
              #default="validationContext"
              name="Código Nocturno"
              rules="required"
            >
              <b-form-group
                label="Código Nocturno"
                label-for="codigoNocturno"
              >
                <b-form-input
                  id="full-name"
                  v-model="itemData.codigoNocturno"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Código Nocturno del Trabajo"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Descripción -->
            <validation-provider
              #default="validationContext"
              name="Descripción"
              rules="required"
            >
              <b-form-group
                label="Descripción"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="full-name"
                  v-model="itemData.descripcion"
                  :state="getValidationState(validationContext)"
                  no-resize
                  placeholder="Descripción del Trabajo"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Cambio de Lámpara? -->
            <b-form-group
              label="Requiere cambio de lámpara?"
            >
              <b-form-checkbox
                v-model="itemData.tipoTrabajo"
                switch
              />
            </b-form-group>
          </div>

          <!-- Tipo de Accesorio (Tamaños)-->
          <validation-provider
            v-if="origen === 32"
            #default="validationContext"
            name="Tipo de Accesorio"
            rules="required"
          >
            <b-form-group
              label="Tipo de Accesorio"
              label-for="tipo"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.tipo"
                :options="tipoAccesorio"
                :state="!!itemData.tipo"
                name="tipoMantenimiento"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Potencia -->
          <validation-provider
            v-if="origen === 32"
            #default="validationContext"
            name="Medida"
            rules="required"
          >
            <b-form-group
              label="Medida"
              label-for="valor"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                v-model.number="itemData.valor"
                :state="!!itemData.valor"
                trim
                type="number"
                placeholder="Medida en Números"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tipo de Unidad (Unidades)-->
          <validation-provider
            v-if="origen === 32"
            #default="validationContext"
            name="Tipo de Accesorio"
            rules="required"
          >
            <b-form-group
              label="Tipo de Unidad"
              label-for="tipo"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.unidad"
                :options="tipoUnidades"
                :state="!!itemData.unidad"
                name="tipoMantenimiento"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tipo de Mantenimiento-->
          <validation-provider
            v-if="origen === 19"
            #default="validationContext"
            name="Tipo de Mantenimiento"
            rules="required"
          >
            <b-form-group
              label="Tipo de Mantenimiento"
              label-for="tipoMantenimiento"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.tipo"
                :options="tipoMantenimientos"
                :state="
                  itemData.tipo === null || itemData.tipo === undefined
                    ? false
                    : true
                "
                name="tipoMantenimiento"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tipo de Falla -->
          <validation-provider
            v-if="origen === 7"
            #default="validationContext"
            name="Tipo de Ruta"
            rules="required"
          >
            <b-form-group
              label="Tipo de Falla"
              label-for="tipoRuta"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.tipoFalla"
                :options="tiposFallas"
                :state="
                  itemData.tipoFalla === null ||
                    itemData.tipoFalla === undefined
                    ? false
                    : true
                "
                name="tipoFalla"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tipo Trabajo -->
          <validation-provider
            v-if="origen === 30"
            #default="validationContext"
            name="Tipo de Trabajo"
            rules="required"
          >
            <b-form-group
              label="Tipo de Trabajo"
              label-for="tipoTrabajo"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.tipoTrabajo"
                :options="tipoTrabajo"
                :state="
                  itemData.tipoTrabajo === null ||
                    itemData.tipoTrabajo === undefined
                    ? false
                    : true
                "
                name="nombre"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Zona -->
          <validation-provider
            v-if="titulo === 'Localidad'"
            #default="validationContext"
            name="Zona"
            rules="required"
          >
            <b-form-group
              label="Zona"
              label-for="zona"
            >
              <b-form-input
                id="zona"
                v-model.number="itemData.zona"
                type="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Municipio -->
          <validation-provider
            v-if="titulo === 'Localidad' && listado.length > 0"
            #default="validationContext"
            name="Municipio"
            rules="required"
          >
            <b-form-group
              label="Municipio"
              label-for="idCompany"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.idMunicipio"
                :options="listado"
                :state="
                  itemData.idMunicipio === null ||
                    itemData.idMunicipio === undefined
                    ? false
                    : true
                "
                name="idCompany"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tipo Ruta -->
          <validation-provider
            v-if="origen === 8"
            #default="validationContext"
            name="Tipo de Ruta"
            rules="required"
          >
            <b-form-group
              label="Tipo de Ruta"
              label-for="tipoRuta"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="itemData.tipoRuta"
                :options="tiposRutas"
                :state="
                  itemData.tipoRuta === null || itemData.tipoRuta === undefined
                    ? false
                    : true
                "
                name="tipoRuta"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Encargado -->
          <validation-provider
            v-if="origen === 8"
            #default="validationContext"
            name="Encargado de Ruta"
            rules="required"
          >
            <b-form-group
              label="Encargado de Ruta"
              label-for="userEncargado"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.userEncargado"
                label="nombre"
                :options="listado"
                :state="
                  itemData.userEncargado === null ||
                    itemData.userEncargado === undefined
                    ? false
                    : true
                "
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Fecha Programación RUta -->
          <validation-provider
            v-if="origen === 8 && itemData.tipoRuta === 'DIURNA'"
            #default="validationContext"
            name="Fecha de Inspección"
            rules="required"
          >
            <b-form-group
              label="Fecha de Inspección"
              label-for="fechaProgramada"
              :state="getValidationState(validationContext)"
            >
              <b-form-datepicker
                id="example-datepicker"
                v-model="itemData.fechaProgramada"
                class="mb-1"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <br>
          <br>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'
import { updateCreateInspeccion } from '@/utils/inspecciones'
import vSelect from 'vue-select'
import { saveItem } from '../utils/catalogos'
import { saveLocalidad } from '../utils/localizacion'

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },
    origen: {
      type: Number,
      required: true,
    },
    municipio: {
      type: String,
      required: false,
      default: 'O',
    },
    listado: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      usuarios: [],
    }
  },
  setup(props, { emit }) {
    const user = JSON.parse(localStorage.getItem('userData'))
    let blackItemData = {
      nombre: '',
      user: user.email,
      active: true,
      companyId: user.companyId,
    }
    if (props.titulo === 'Listado') {
      blackItemData = {
        nombre: '',
        zona: '',
        idMunicipio: props.municipio,
        user: user.email,
        active: true,
      }
    }

    if (props.origen === 8) {
      blackItemData = {
        nombre: '',
        tipoRuta: null,
        fechaProgramada: null,
        companyId: user.company.id,
        user: user.email,
        userEncargado: null,
        active: true,
      }
    } else if (props.origen === 7) {
      blackItemData = {
        nombre: '',
        tipoFalla: null,
        user: user.email,
        active: true,
      }
    } else if (props.origen === 19) {
      blackItemData = {
        nombre: '',
        tipo: null,
        user: user.email,
        active: true,
      }
    }

    if (props.origen === 30) {
      blackItemData.tipoTrabajo = null
      blackItemData.tipo = 'LEVANTAMIENTO'
    }

    if (props.origen === 33) {
      blackItemData.tipo = 'CORRECTIVO'
    }

    if (props.origen === 10) blackItemData.tipo = 'AMPLIACION'

    blackItemData.companyId = user.company.id

    const itemData = ref(JSON.parse(JSON.stringify(blackItemData)))
    const resetuserData = () => {
      itemData.value = JSON.parse(JSON.stringify(blackItemData))
    }

    function mensajeError(mensaje) {
      Swal.fire({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }

    function success() {
      Swal.fire({
        title: 'Registro Almacenado Correctamente',
        text: 'Registro Almacenado Correctamente',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }

    const tipoUnidades = [
      {
        value: null,
        text: 'Seleccione un tipo de unidad',
      },
      {
        value: 'METROS',
        text: 'METROS',
      },
      {
        value: 'PIES',
        text: 'PIES',
      },
      {
        value: 'PULGADAS',
        text: 'PULGADAS',
      },
      {
        value: 'CENTIMETROS',
        text: 'CENTÍMETROS',
      },
    ]

    const tiposRutas = [
      {
        value: null,
        text: 'Seleccione un tipo de Ruta',
      },
      {
        value: 'NOCTURNA',
        text: 'RUTA NOCTURNA',
      },
      {
        value: 'DIURNA',
        text: 'RUTA DIURNA',
      },
    ]

    const tipoTrabajo = [
      {
        value: null,
        text: 'Seleccione un tipo de trabajo',
      },
      {
        value: 'POSTE',
        text: 'TRABAJO DE POSTE',
      },
      {
        value: 'BRAZO',
        text: 'TRABAJO DE BRAZO',
      },
      {
        value: 'LAMPARA',
        text: 'TRABAJO DE LÁMPARA',
      },
      {
        value: 'CONEXION',
        text: 'TRABAJO DE CONEXIÓN',
      },
      {
        value: 'OTROS',
        text: 'OTROS TRABAJOS',
      },
    ]

    const tipoAccesorio = [
      {
        value: null,
        text: 'Seleccione un tipo de Accesorio',
      },
      {
        value: 'POSTE',
        text: 'POSTE',
      },
      {
        value: 'BRAZO',
        text: 'BRAZO',
      },
    ]

    const tiposFallas = [
      {
        value: null,
        text: 'Seleccione un tipo de Falla',
      },
      {
        value: 'FALLA',
        text: 'Tickets de Fallas',
      },
      {
        value: 'MANTENIMIENTO',
        text: 'Tickets de Mantenimientos',
      },
    ]

    const tipoMantenimientos = [
      {
        value: null,
        text: 'Seleccione un tipo de Mantenimiento',
      },
      {
        value: 'preventivo',
        text: 'Mantenimientos Preventivo',
      },
      {
        value: 'correctivo',
        text: 'Mantenimientos Correctivo',
      },
    ]

    const onSubmit = async () => {
      try {
        if (itemData.value.tipoTrabajo && props.origen === 33) {
          itemData.value.tipoTrabajo = 'CAMBIO_LAMPARA'
        }

        let body = JSON.stringify(itemData.value)
        body = JSON.parse(body)
        if (props.titulo === 'Localidad') {
          if (Number(props.municipio) !== 0 && props.listado.length === 0) {
            body.idMunicipio = Number(props.municipio)
          }
          delete body.companyId
        } else {
          body.companyId = user.company.id
        }
        let result
        if (props.titulo === 'Localidad') result = await saveLocalidad(JSON.stringify(body))
        else {
          result = await saveItem(
            props.origen,
            JSON.stringify(body),
            props.titulo,
            1,
          )
        }
        if (result !== null) {
          if (props.origen === 8) {
            const inspeccion = {
              rutaId: result.id,
              companyId: body.companyId,
              tipo: body.tipoRuta,
              fechaCreacion: new Date(),
              usuario: body.userEncargado,
              estado: 'PENDIENTE',
              imagenes: [],
              observaciones: '',
            }
            await updateCreateInspeccion(inspeccion, 2)
          }

          success()
          emit('refetch-data', result)
          emit('update:is-add-new-user-sidebar-active', false)
        } else {
          mensajeError(
            'Inconvenientes almacenando el registro, intente nuevamente!',
          )
        }
      } catch (e) {
        mensajeError(
          'Inconvenientes almacenando el registro, intente nuevamente!',
        )
        console.error('Error guardando Catalogo', e)
        console.error('Item Fail Save', itemData)
      }
    }

    const onClose = () => {
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData)

    return {
      itemData,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      onClose,
      tiposRutas,
      tiposFallas,
      tipoMantenimientos,
      tipoTrabajo,
      tipoAccesorio,
      tipoUnidades,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
