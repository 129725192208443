import RequestAdmin from '@/utils/request'
import config from '@/utils/config'

const zonas = [
  {
    id: 1,
    nombre: 'Zona 1',
    value: '1',
  },
  {
    id: 2,
    nombre: 'Zona 2',
    value: '2',
  },
  {
    id: 3,
    nombre: 'Zona 3',
    value: '3',
  },
  {
    id: 4,
    nombre: 'Zona 4',
    value: '4',
  },
  {
    id: 5,
    nombre: 'Zona 5',
    value: '5',
  },
  {
    id: 6,
    nombre: 'Zona 6',
    value: '6',
  },
  {
    id: 7,
    nombre: 'Zona 7',
    value: '7',
  },
  {
    id: 8,
    nombre: 'Zona 8',
    value: '8',
  },
  {
    id: 9,
    nombre: 'Zona 9',
    value: '9',
  },
  {
    id: 10,
    nombre: 'Zona 10',
    value: '10',
  },
  {
    id: 11,
    nombre: 'Zona 11',
    value: '11',
  },
  {
    id: 12,
    nombre: 'Zona 12',
    value: '12',
  },
]

export function getListadoZonas(tipo) {
  if (tipo === 1) return zonas
  return []
}

const urlDepartamentos = `${config.URL_BASE}departamentos`
const urlMunicipios = `${config.URL_BASE}municipios`
const urlLocalidades = `${config.URL_BASE}localidades`

// **************************************************  DEPARTAMENTOS *********************************************/
// eslint-disable-next-line import/prefer-default-export
export async function getDepartamentos(filter) {
  try {
    const request = new RequestAdmin()
    if (filter) return request.getList(`${urlDepartamentos}?filter[include][]=municipios`, 'Municipios')
    return request.getList(urlDepartamentos, 'Departamentos')
  } catch (err) {
    console.error('Error en cargar Deparamentos', err)
    return []
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getDepartamento(id) {
  try {
    const request = new RequestAdmin()
    const url = `${urlDepartamentos}/${id}?filter[include][]=municipios`
    return request.executeGet(url)
  } catch (err) {
    console.error('Error en cargar Departamento', err)
    return []
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function updateItem(item, tipo) {
  try {
    const request = new RequestAdmin()
    const body = {
      id: item.id,
      nombre: item.nombre,
      codigoRenap: item.codigoRenap,
      active: item.active.value,
    }
    if (tipo === 2) body.idDepartamento = item.idDepartamento
    if (tipo === 1) return request.executePostPutPatch(`${urlDepartamentos}/${item.id}`, body, 'PATCH')
    return request.executePostPutPatch(`${urlMunicipios}/${item.id}`, body, 'PATCH')
  } catch (err) {
    console.error('Error en cargar Departamento', err)
    return []
  }
}

// **************************************************  MUNICIPIOS *********************************************/
// eslint-disable-next-line import/prefer-default-export
export async function getMunicipios(filter) {
  try {
    const request = new RequestAdmin()
    if (filter) return request.getList(`${urlMunicipios}?filter[include][]=departamento`, 'Municipios')
    return request.getList(urlMunicipios, 'Municipios')
  } catch (err) {
    console.error('Error en cargar Municipios', err)
    return []
  }
}

export async function getMunicipio(id, tipo = 1) {
  try {
    const request = new RequestAdmin()
    const url = `${urlMunicipios}/${id}`
    const data = await request.executeGet(`${url}?filter[include][]=departamento`)
    if (tipo !== 1) {
      data.localidades = await request.getList(`${url}/localidads`, 'Municipios')
    }
    return data
  } catch (err) {
    console.error('Error en cargar Municipio', err)
    return []
  }
}

// **************************************************  LOCALIDADES *********************************************/
// eslint-disable-next-line import/prefer-default-export
export async function getLocalidades(filtro, municipio = null) {
  try {
    const request = new RequestAdmin()
    if (filtro) return request.getList(`${urlLocalidades}?filter[include][]=municipio`, 'Localidades')

    let url = urlLocalidades
    if (municipio !== null) url = `${config.URL_BASE}municipios/${municipio}/localidads`
    const lista = await request.getList(url, 'Localidades')
    // eslint-disable-next-line no-return-assign,no-param-reassign,array-callback-return
    lista.map(local => {
      // eslint-disable-next-line no-param-reassign
      local.name = `${local.nombre} (${local.zona})`
      // eslint-disable-next-line no-param-reassign
      local.zname = `${local.nombre} - ZONA ${local.zona}`
    })
    return lista
  } catch (err) {
    console.error('Error en cargar Localidades', err)
    return []
  }
}

export async function getLocalidadesFilter(filter) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${urlLocalidades}?filter=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en cargar Localidades', err)
    return []
  }
}

export async function getLocalidad(id) {
  try {
    const request = new RequestAdmin()
    const url = `${urlLocalidades}/${id}?filter[include][]=municipio`
    return request.executeGet(`${url}`)
  } catch (err) {
    console.error('Error en cargar Localidad', err)
    return []
  }
}

export async function updateLocalidad(item) {
  try {
    const request = new RequestAdmin()
    const body = {
      id: item.id,
      nombre: item.nombre,
      zona: item.zona,
      idMunicipio: item.idMunicipio,
      active: item.active.value,
    }
    return request.executePostPutPatch(`${urlLocalidades}/${item.id}`, body, 'PATCH')
  } catch (err) {
    console.error('Error en cargar Localidad', err)
    return []
  }
}

export async function saveLocalidad(item) {
  try {
    // eslint-disable-next-line no-param-reassign
    item = JSON.parse(item)
    // eslint-disable-next-line no-param-reassign
    item.zona = item.zona.toString()
    console.log(item)
    const request = new RequestAdmin()
    return request.executePostPutPatch(`${urlLocalidades}`, item, 'POST')
  } catch (err) {
    console.error('Error en cargar Localidad', err)
    return null
  }
}
