import RequestAdmin from '@/utils/request'
import config from '@/utils/config'

const url = `${config.URL_BASE}usuarios`

export async function getAllUsers(withCompanies = false) {
  try {
    const request = new RequestAdmin()
    let finalUrl = url
    if (withCompanies) finalUrl += '/company'
    const list = await request.getList(finalUrl, 'Usuario')
    return list
  } catch (err) {
    console.error('Error en cargar Usuarios', err)
    return []
  }
}

export function getRoles(user) {
  return user.company.roles
}

export async function getUsuario(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    const item = await request.executeGet(`${url}/${id}`)
    return item
  } catch (err) {
    console.error('Error en cargar Usuario', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getUsersByCompany(company) {
  try {
    const request = new RequestAdmin()
    const list = await request.getList(url, 'Usuario')
    return list.filter(usuario => usuario.idCompany === company)
  } catch (err) {
    console.error('Error en cargar Usuarios', err)
    return []
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getUsersByCompanyRole(company, role) {
  try {
    const request = new RequestAdmin()
    const list = await request.getList(url, 'Usuario')
    return list.filter(usuario => usuario.idCompany === company && usuario.role === role && usuario.active === true)
  } catch (err) {
    console.error('Error en cargar Usuarios', err)
    return []
  }
}

export async function updateCreateItem(item, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) return await request.executePostPutPatch(`${url}/${item.id}`, item, 'PATCH')
    // eslint-disable-next-line no-param-reassign
    delete item.id
    const response = await request.executePostPutPatch(`${url}`, item, 'POST', true)
    return response
  } catch (err) {
    console.error('Error en gestionar Usuario', err)
    return null
  }
}
