<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Agregar Nuevo Usuario
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          v-no-autocomplete
          class="p-2"
          autocomplete="off"
          @submit.prevent="handleSubmit(AddUser)"
          @reset.prevent="resetForm"
        >

          <!-- Primer Nombre -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaPrimerNombre"
            rules="required"
          >
            <b-form-group
              :label="etiquetaPrimerNombre"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                v-model="userData.firstName"
                v-no-autocomplete
                autocomplete="off"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Segundo Nombre -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaSegundoNombre"
          >
            <b-form-group
              :label="etiquetaSegundoNombre"
              label-for="secondName"
            >
              <b-form-input
                id="secondName"
                v-model="userData.secondName"
                v-no-autocomplete
                autocomplete="off"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Primer Apellido -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaPrimerApellido"
            rules="required"
          >
            <b-form-group
              :label="etiquetaPrimerApellido"
              label-for="firstSurname"
            >
              <b-form-input
                id="firstSurname"
                v-model="userData.firstSurname"
                v-no-autocomplete
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Primer Apellido -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaSegundoApellido"
          >
            <b-form-group
              :label="etiquetaSegundoApellido"
              label-for="secondSurname"
            >
              <b-form-input
                id="secondSurname"
                v-model="userData.secondSurname"
                v-no-autocomplete
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="Rol del Usuario"
            rules="required"
          >
            <b-form-group
              label="Rol del Usuario"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="userData.role"
                :options="roles"
                :state="userData.role === '' ? false : true"
                name="user-role"
                @change="cambiarValores"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- EMPRESA -->
          <validation-provider
            #default="validationContext"
            name="Empresa"
            rules="required"
          >
            <b-form-group
              label="Nombre Empresa"
              label-for="empresa"
            >
              <b-form-input
                id="empresa"
                v-model="userData.empresa"
                v-no-autocomplete
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            v-if="usuario.role === 'superadmin'"
            #default="validationContext"
            name="Empresa"
            rules="required"
          >
            <b-form-group
              label="Empresa"
              label-for="idCompany"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                v-model="userData.idCompany"
                :options="listCompanies"
                :state="userData.idCompany === null || userData.idCompany === undefined ? false : true"
                name="user-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- EMAIL -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaEmail"
            rules="required|email"
          >
            <b-form-group
              :label="etiquetaEmail"
              label-for="email"
            >
              <b-form-input
                id="email-user-new"
                v-model="userData.email"
                v-no-autocomplete
                autocomplete="off"
                type="email"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- TELEFONO -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaTelefono"
            rules="required"
          >
            <b-form-group
              :label="etiquetaTelefono"
              label-for="telefono"
            >
              <b-form-input
                id="telefono"
                v-model.number="userData.telefono"
                v-no-autocomplete
                type="number"
                autocomplete="off"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- PASSWORD -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaPassword"
            rules="required|password"
          >
            <b-form-group
              :label="etiquetaPassword"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                v-no-autocomplete
                type="password"
                autocomplete="off"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- CONFIRM PASSWORD -->
          <validation-provider
            #default="validationContext"
            :name="etiquetaConfirmPassword"
            rules="required|password"
          >
            <b-form-group
              :label="etiquetaConfirmPassword"
              label-for="password"
            >
              <b-form-input
                id="passwordConfirm"
                v-model="passwordConfirm"
                v-no-autocomplete
                type="password"
                autofocus
                autocomplete="off"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- REQUIERE CAMBIO PASSWORD -->
          <!-- <b-form-group
            :label="etiquetaRequiereCambio"
            label-for="temporalPassword"
          >
            <b-form-checkbox
              v-model="userData.temporalPassword"
              :checked="userData.temporalPassword"
              class="custom-control-secondary"
              name="temporalPassword"
              switch
            />
          </b-form-group> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getRoles, updateCreateItem } from '@/utils/usuarios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    // BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    listCompanies: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      roles: getRoles(JSON.parse(localStorage.getItem('userData'))),
      companies: [],
      etiquetaPrimerNombre: 'Primer Nombre',
      etiquetaSegundoNombre: 'Segundo Nombre',
      etiquetaPrimerApellido: 'Primer Apellido',
      etiquetaSegundoApellido: 'Segundo Apellido',
      etiquetaDPI: 'DPI',
      etiquetaEmail: 'Correo Electrónico',
      etiquetaTelefono: 'Teléfono',
      etiquetaPassword: 'Contraseña',
      etiquetaConfirmPassword: 'Confirmar contraseña',
      // etiquetaRequiereCambio: 'Se solicita cambio de contraseña?',
      mostrarCampos: true,
      passwordConfirm: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async beforeMount() {
    this.etiquetaNombre = 'Primer Nombre'
    this.mostrarCampos = true
    this.cambiarValores()
  },
  setup() {
    const blankUserData = {
      active: true,
      email: '',
      role: '',
      empresa: '',
      password: '',
      firstName: '',
      secondName: '',
      otherNames: '',
      firstSurname: '',
      secondSurname: '',
      marriedSurname: '',
      idCompany: null,
      temporalPassword: true,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData,
    )

    return {
      userData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async AddUser() {
      try {
        if (this.passwordConfirm !== this.userData.password) {
          this.error('Las contraseñas no coinciden!')
          return
        }
        this.userData.telefono = this.userData.telefono.toString()

        if (!this.usuario.role !== 'superadmin') {
          this.userData.idCompany = this.listCompanies[0].value
        }

        // delete this.userData.passwordConfirm
        updateCreateItem(this.userData, 2)
          .then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Usuario Ingresado',
                  icon: 'EditIcon',
                  variant: 'success',
                  text: 'Usuario se a ingresado correctamente!!',
                },
              })
              this.$emit('update:is-add-new-user-sidebar-active', false)
              this.$emit('refetch-data', response.data)
            } else if (response.data) {
              this.error(response.data.error.message)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Usuario no se ha Registrado',
                  icon: 'EditIcon',
                  variant: 'warning',
                  text: 'No se pudo ingresar el Usuario',
                },
              })
            }
          })
          .catch(err => {
            console.error('Error generando usuario, ', err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuario no se ha Registrado',
                icon: 'EditIcon',
                variant: 'warning',
                text: 'No se pudo ingresar el Usuario.',
              },
            })
          })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario no se ha Registrado',
            icon: 'EditIcon',
            variant: 'warning',
            text: 'No se pudo ingresar el Usuario.',
          },
        })
        console.error('Error generando usuario, ', err)
      }
    },
    cambiarValores() {
      if (this.userData.idRol === 3) {
        this.mostrarCampos = false
      } else {
        this.mostrarCampos = true
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
