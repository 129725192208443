<template>
  <div>
    <new-item-catalog
      :is-add-new-user-sidebar-active.sync="isAddNewItemSidebarActive"
      :origen="tipoCatalogo"
      :titulo="origen"
      :listado="companies"
      :municipio="municipio"
      @refetch-data="refetchData"
    />
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :list-companies="companies"
      @refetch-data="refetchData"
    />

    <b-row>
      <b-col cols="8">
        &nbsp;
      </b-col>
      <b-col cols="4">
        <table style="margin-top: 25px; margin-left: 25px;">
          <tr>
            <td>
              <div v-if="showIconNew">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  @click="showNew"
                >
                  <span class="text-nowrap">Agregar {{ origen }}</span>
                </b-button>
              </div>
            </td>
            <td>
              <div v-if="tipoCatalogo===33">
                <reporte-trabajos-correctivos :catalogos="tableData" />
              </div>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <div

      class="m-2"
    >
      <!-- Table Top -->
      <b-row />
    </div>
    <br>
    <!-- search input -->
    <div v-if="showSearch">
      <b-form-group>
        <label>Buscar: </label>
        <b-form-input
          v-model="searchTerm"
          placeholder="Buscar"
          type="text"
        />
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      ref="refTable"
      class="position-relative"
      :columns="tableColumns"
      :rows="tableData"
      rlt="false"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      style-class="vgt-table condesed"
    >
      <template
        slot="table-row"
        slot-scope="props"
        class="vgt-center-align"
      >
        <!-- Column: Nombre Completo -->
        <span
          v-if="props.column.field === 'nombreCompleto'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: `${redirectShow}`,
              params: { id: props.row.id, origen: origen, action: 'Show' },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ props.row.firstName }} {{ props.row.secondName }}
            {{ props.row.firstSurname }} {{ props.row.secondSurname }}
          </b-link>
        </span>

        <!-- Column: Nombre -->
        <span
          v-else-if="props.column.label === 'Usuario Registra'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: `${redirectShow}`,
              params: { id: props.row.id, origen: origen, action: 'Show' },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{
              props.row.usuario.nombre
                ? props.row.usuario.nombre
                : props.row.nombre
            }}
          </b-link>
        </span>

        <!-- Column: Nombre -->
        <span
          v-else-if="
            props.column.field === 'nombre' ||
              props.column.field === 'name' ||
              props.column.label === ''
          "
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: `${redirectShow}`,
              params: { id: props.row.id, origen: origen, action: 'Show' },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ props.row.nombre ? props.row.nombre : props.row.name }}
          </b-link>
        </span>

        <!-- Column: numeroPoste -->
        <span
          v-else-if="props.column.field === 'numeroPoste'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: `${redirectShow}`,
              params: { id: props.row.id, origen: origen, action: 'Show' },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ props.row.numeroPoste }}
          </b-link>
        </span>

        <!-- Column: Estado -->
        <span
          v-else-if="props.column.field === 'active'"
          class="text-nowrap"
        >
          <b-badge
            pill
            :variant="`light-${getStatusClass(props.row.active)}`"
            :class="`text-${getStatusClass(props.row.active)}`"
          >
            {{ getStatusName(props.row.active) }}
          </b-badge>
        </span>

        <!-- Column: Rol -->
        <span
          v-else-if="props.column.field === 'role'"
          class="text-nowrap"
        >
          <div class="text-nowrap">
            <feather-icon
              :icon="getUserRoleIcon(props.row.role)"
              size="18"
              class="mr-50"
              :class="`text-${getTypeRol(props.row.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              props.row.role
            }}</span>
          </div>
        </span>

        <span
          v-else-if="props.column.field === 'fechaCreacion'"
          class="text-nowrap"
        >
          {{ calcularFecha(props.row.fechaCreacion) }}
        </span>

        <span
          v-else-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >
          {{ calcularFecha(props.row.created_at) }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'acciones'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: `${redirectEdit}`,
                  params: {
                    id: props.row.id,
                    origen: `${origen}`,
                    action: 'Edit',
                  },
                }"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  origen === 'Puntos de Iluminiación' &&
                    usuario.company.municipio !== 8 &&
                    !props.row.detalle
                "
                :to="{
                  name: 'puntos-iluminacion-levantamiento',
                  params: { id: props.row.id },
                }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Levantamiento de Datos</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span
          v-else-if="
            props.column.field === 'levantamiento' &&
              usuario.company.municipio !== 8
          "
        >
          <span>
            <b-button
              :to="{
                name: 'puntos-iluminacion-levantamiento',
                params: { id: props.row.id },
              }"
              variant="relief-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span>Levantamiento de Datos</span>
            </b-button>
          </span>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrar </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '15']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormSelect,
  BPagination,
  BFormGroup,
  BFormInput,
  BCol,
  BDropdownItem,
  BDropdown,
  BBadge,
  BLink,
  BButton,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ref } from '@vue/composition-api'
import 'vue-good-table/dist/vue-good-table.css'
import NewItemCatalog from '@/components/NewItemCatalog.vue'
import Ripple from 'vue-ripple-directive'
import ReporteTrabajosCorrectivos from '@/utils/reportes/catalogos/reporteTrabajosCorrectivos.vue'
import UserListAddNew from '../views/administracion/Usuarios/UserListAddNew.vue'

export default {
  name: 'ListTableData',
  components: {
    ReporteTrabajosCorrectivos,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    NewItemCatalog,
    UserListAddNew,
  },
  directives: {
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    companies: {
      type: Array,
      required: false,
    },
    showIconNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    redirectEdit: {
      type: String,
      required: true,
    },
    redirectShow: {
      type: String,
      required: true,
    },
    origen: {
      type: String,
      required: true,
    },
    tipoCatalogo: {
      type: Number,
      required: false,
      default: 0,
    },
    municipio: {
      type: String,
      required: false,
      default: 'O',
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const isAddNewItemSidebarActive = ref(false)
    const isAddNewUserSidebarActive = ref(false)
    const refUserListTable = ref(null)
    const refetchData = row => {
      props.tableData.push(row)
    }
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      isAddNewItemSidebarActive,
      refetchData,
      refUserListTable,
    }
  },
  data() {
    return {
      refUserListTable: ref(null),
      searchTerm: '',
      pageLength: 10,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    showNew() {
      if (this.origen === 'Usuario') this.isAddNewUserSidebarActive = true
      else this.isAddNewItemSidebarActive = true
    },
    getTypeRol(role) {
      if (role === 'admin') return 'primary'
      if (role === 'reportes') return 'secondary'
      return 'warning'
    },
    getUserRoleIcon(role) {
      if (role === 'admin') return 'UserIcon'
      if (role === 'reportes') return 'FileTextIcon'
      return 'UserIcon'
    },
    getStatusName(status) {
      if (status === undefined) return 'ACTIVO'
      if (status === true) return 'ACTIVO'
      return 'INACTIVO'
    },
    getStatusClass(status) {
      if (status === undefined) return 'success'
      if (status === true) return 'success'
      return 'warning'
    },
    calcularFecha(fechaString) {
      const date = new Date(fechaString)
      return date.toLocaleString('es-GT')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped></style>
