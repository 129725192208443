<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
      v-b-tooltip.hover.v-warning
      title="Descargar Reporte"
      variant="warning"
      class="btn-icon"
      @click="generarExcel(catalogos)"
    >
      <feather-icon
        icon="DownloadIcon"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    catalogos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    recalcularTotal(item) {
      let total = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const row of item.items) {
        total += row.precio ? row.precio : 0
      }
      return total
    },
    async generarExcel(data) {
      // eslint-disable-next-line global-require,no-shadow
      const xl = require('excel4node')
      const wb = new xl.Workbook()
      const ws = wb.addWorksheet('Hoja 1')
      const headerText = this.usuario.company.companyStyle.headerReportes

      const styleTitulo = wb.createStyle({
        font: {
          bold: true,
          size: 20,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleSubtitulo = wb.createStyle({
        font: {
          bold: true,
          size: 10,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleValores = wb.createStyle({
        font: {
          bold: true,
          size: 10,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleCeldas = wb.createStyle({
        font: {
          size: 10,
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })

      ws.cell(1, 1, 2, 9, true).string(headerText).style(styleTitulo)
      ws.cell(4, 1, 4, 9, true).string('REPORTE DE TRABAJOS CORRECTIVOS').style(styleSubtitulo)

      // <!---------Titulos de tabla ---------------->
      ws.cell(6, 1).string('Nombre').style(styleValores)
      ws.cell(6, 2).string('Código').style(styleValores)
      ws.cell(6, 3).string('Código Nocturno').style(styleValores)
      ws.cell(6, 4).string('Descripción').style(styleValores)
      ws.cell(6, 5).string('Requiere Cambio Lámpara').style(styleValores)
      ws.cell(6, 6).string('Costo').style(styleValores)
      ws.cell(6, 7).string('Venta').style(styleValores)
      ws.cell(6, 8).string('Costo Nocturno').style(styleValores)
      ws.cell(6, 9).string('Venta Nocturno').style(styleValores)

      let numeroFila = 7
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of data) {
        ws.cell(numeroFila, 1).string(item.nombre).style(styleCeldas)
        ws.cell(numeroFila, 2).string(item.codigo).style(styleCeldas)
        ws.cell(numeroFila, 3).string(item.codigoNocturno).style(styleCeldas)
        ws.cell(numeroFila, 4).string(item.descripcion).style(styleCeldas)
        ws.cell(numeroFila, 5).string(item.tipoTrabajo ? 'SI' : 'NO').style(styleCeldas)
        ws.cell(numeroFila, 6).number(item.precio ? item.precio.costo : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
        ws.cell(numeroFila, 7).number(item.precio ? item.precio.precioVenta : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
        ws.cell(numeroFila, 8).number(item.precio ? item.precio.costoNocturno : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
        ws.cell(numeroFila, 9).number(item.precio ? item.precio.precioVentaNocturno : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })

        // eslint-disable-next-line no-plusplus
        numeroFila++
      }

      ws.column(1).setWidth(40)
      ws.column(2).setWidth(15)
      ws.column(3).setWidth(15)
      ws.column(4).setWidth(50)
      ws.column(5).setWidth(20)
      ws.column(6).setWidth(15)
      ws.column(7).setWidth(15)
      ws.column(8).setWidth(15)
      ws.column(9).setWidth(15)

      const buffer = await wb.writeToBuffer()
      const string = buffer.toString('base64')
      const linkSource = `data:application/xlsx;base64,${string}`
      const downloadLink = document.createElement('a')
      const fileName = 'Trabajos Correctivos.xlsx'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  },
}
</script>
