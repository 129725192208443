import RequestAdmin from '@/utils/request'
import config from '@/utils/config'

const url = `${config.URL_BASE}inspecciones`
const urlAmpliaciones = `${config.URL_BASE}ampliaciones`

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateInspeccion(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Inspección', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findInspecciones(filter) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}?filter=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en obtener Inspecciones', err)
    return null
  }
}

export async function count(site, where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${site}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener count Ampliaciones / Inspecciones', err)
    return null
  }
}

export async function counInspecciones(where) {
  return count(url, where)
}

export async function countAmpliaciones(where) {
  return count(urlAmpliaciones, where)
}

// eslint-disable-next-line import/prefer-default-export
export async function findAmpliaciones(filter) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${urlAmpliaciones}?filter=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en obtener Ampliaciones', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findInspeccionId(Id) {
  try {
    const request = new RequestAdmin()
    console.log(`${url}/${Id}`)
    return request.executeGet(`${url}/${Id}`)
  } catch (err) {
    console.error('Error en obtener Inspección', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findAmplicacionId(Id) {
  try {
    const request = new RequestAdmin()
    console.log(`${urlAmpliaciones}/${Id}`)
    return request.executeGet(`${urlAmpliaciones}/${Id}`)
  } catch (err) {
    console.error('Error en obtener Ampliación', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findByPoste(posteId) {
  try {
    const request = new RequestAdmin()
    const filter = {
      where: {
        and: [
          {
            idPunto: posteId,
          },
        ],
      },
    }
    return request.executeGet(`${url}?filter=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en obtener Inspección por Poste', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateAmpliacion(item, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 3) return request.executePostPutPatch(`${urlAmpliaciones}/actualizacion/${item.id}`, item, 'PATCH')
    if (tipo === 1) return request.executePostPutPatch(`${urlAmpliaciones}/${item.id}`, item, 'PATCH')
    // eslint-disable-next-line no-param-reassign
    delete item.id
    return request.executePostPutPatch(`${urlAmpliaciones}`, item, 'POST')
  } catch (err) {
    console.error('Error en guardar / Actualizar Ampliación', err)
    return []
  }
}
