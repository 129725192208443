import axios from 'axios'
import JwtService from '@/auth/jwt/jwtService'
// import { datadogLogs } from '@datadog/browser-logs'

const user = JSON.parse(localStorage.getItem('userData'))
/* axios.interceptors.request.use(request => {
  if (/files\/.+\/.+/.test(request.url) || /documentos\/.+\/.+/.test(request.url)) {
    return request
  }

  datadogLogs.logger.info('Request sent', {
    url: request.url,
    method: request.method,
    data: request.data,
    user_id: user.id,
  })
  return request
}, error => {
  datadogLogs.logger.error('Request error', {
    error,
    user_id: user.id,
  })
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  datadogLogs.logger.info('Response received', {
    url: response.config.url,
    status: response.status,
    data: response.data,
    user_id: user.id,
  })
  return response
}, error => {
  datadogLogs.logger.error('Response error', {
    error,
    user_id: user.id,
  })
  return Promise.reject(error)
}) */

export default class RequestAdmin {
  async getArraySelect(url) {
    try {
      const data = await this.executeGet(url)
      if (data === null) return []
      const array = []
      data.forEach(item => {
        array.push({ text: item.name, value: item.id })
      })
      return array
    } catch (error) {
      console.error('error executeGet', error)
      return []
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getList(url, catalog) {
    try {
      let items = await this.executeGet(url)
      if (items === null) items = []
      return items
    } catch (err) {
      console.error(`Error en cargar ${catalog}`, err)
      return []
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async executeGet(url) {
    try {
      const jwt = new JwtService()
      const token = await jwt.getToken()
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return resp.data
    } catch (error) {
      console.error('error executeGet', error)
      return null
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async executePostPutPatch(url, body, method, user = false) {
    let response
    try {
      const jwt = new JwtService()
      const token = await jwt.getToken()
      if (method === 'POST') {
        response = await axios.post(
          url, body,
          {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              // Authorization: `Bearer ${token}`,
            },
          },
        )
        if (user) return response
        return response.data
      }
      if (method === 'PATCH') {
        response = await axios.patch(
          url, body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        return response.data || true
      }
      response = await axios.put(
        url, body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data
    } catch (error) {
      console.error('error executePostPut', error)
      if (method === 'POST' && user) return error.response
      return null
    }
  }
}
